.queryList {
    background: white;
    padding: 20px 0px 20px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex: 1;
    
    @media (max-width: 768px) {
        padding: 10px 0px 10px 10px;
    
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
          padding: 8px 0px 8px 8px;
          font-size: 14px;
        }
    
        .ant-table-tbody > tr > td {
          white-space: normal; 
        }
      }
    
      @media (max-width: 480px) {
        h2 {
          font-size: 16px; 
        }
    
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
          font-size: 12px;
        }
      }
    
      @media (min-width: 769px) {
        padding: 20px 0px 20px 20px;
    
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
          padding: 12px 0px 12px 12px;
          font-size: 16px;
        }
      }
    
      @media (min-width: 1024px) {
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
          padding: 16px 0px 16px 16px;
          font-size: 18px;
        }
      }

      @media (min-width: 1366px) and (max-width: 1820px) {
        grid-column-start: auto;
        grid-column-end: span 1;
        width: 100%;
      }
      @media (min-width: 760px) and (max-width: 1280px)  {
        grid-column-start: auto;
        grid-column-end: span 2;
        
      }
      
      @media (min-width: 1820px) and (max-width: 2560px) {
        grid-column-start: auto;
        grid-column-end: span 1;
        width: 100%;
    
      }      
}   
.chartHeader{
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #595959;

}