.PeopleBody {
  display: flex;
  flex-direction: column;

  &__invitebutton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 22px 0px;

    &__search {
      span {
        svg {
          height: 12.5px;
          fill: #262626;
        }
      }
    }
  }

  &__staff {
    display: flex;
    flex-direction: column;
    padding: 20px 0px 10px 0px;
    border-top: 1px solid #e3e8f2;
    &__header {
      display: flex;
      justify-content: space-between;
      span {
        width: 147px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #424242;
      }
      span:last-child {
        margin-left: 16px;
      }
    }

    &__body {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;
      align-items: center;

      &__name {
        display: flex;
        flex-direction: column;
        span {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #424242;
        }
        p {
          margin-top: 2px;
          font-family: Inter;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #8c8c8c;
          margin-bottom: 0;
        }
      }
      > span {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #424242;
        margin-top: 5px;
      }
      &__actionContainer {
        width: 147px;
        display: flex;
        justify-content: space-between;
        &__select {
          padding: 5px 16px 5px auto;
          margin-right: 16px;
          min-width: 100px;
          background: #fafafa;
          box-sizing: border-box;
          border-radius: 2px;
        }
        &__delete {
          border-radius: 2px;
          background-color: #fff1f0;
          padding: 4px 8px;
          display: flex;
          align-items: center;
          span {
            svg {
              height: 22px;
              width: 15px;
              fill: #f5222d;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1050px) {
  .PeopleBody {
    &__staff {
      &__body {
        &__actionContainer {
          width: 30%;
          text-align: center;
        }
      }
    }
  }
}
@media (max-width: 980px) {
  .PeopleBody {
    &__invitebutton {
      padding-left: 10px;
    }
  }
}
@media (max-width: 530px) {
  .PeopleBody {
    &__staff {
      &__header {
        span {
          width: 40%;
        }
      }
      &__body {
        &__actionContainer {
          width: 50%;
          text-align: center;
          &__select {
            min-width: 65%;
            text-align: left;
            :global {
              .ant-select-selection-item {
                padding-left: 16px;
              }
            }
          }
        }
      }
    }
  }
}
